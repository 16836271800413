import { Box, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import SingleBaseBetSettings, {
  DetailBetLimitRange,
} from '../../../../base/components/bet-setting/bet-wheel-picker-container/single-base-bet-settings';
import { roundToTwo } from '../../../../services/util/formatNumber';

const useStyles = makeStyles({
  detailBetLimitRange: {
    padding: 20,
    backgroundColor: '#F0F1F2',
    borderRadius: 4,
    "&>*[class*='disabledDetailBetLimitContainer']": {
      fontWeight: '500 !important',
    },
  },
  select: {
    width: 80,
  },
  selectContainer: {
    width: 'fit-content',
  },
});

const BetSettingSelector = () => {
  const styles = useStyles();
  const translate = useTranslate();
  const record = useRecordContext();
  const baseBets = useMemo(
    () => record.game?.gameConfig?.multiBaseBets?.sort((a, b) => a - b) || [record.game?.gameConfig?.baseBet],
    [record],
  );
  const [currentBaseBet, setCurrentBaseBet] = useState(baseBets[0]);

  const betSizes = useMemo(
    () => record.betSizes
      ?.filter(
        item => item.baseBet === currentBaseBet || (!item.baseBet && currentBaseBet === record.game.gameConfig?.baseBet),
      )
      .sort((a, b) => a.value - b.value) || [],
    [currentBaseBet],
  );
  const betLevels = useMemo(
    () => record.betLevels
      ?.filter(
        item => item.baseBet === currentBaseBet || (!item.baseBet && currentBaseBet === record.game.gameConfig?.baseBet),
      )
      .sort((a, b) => a.value - b.value) || [],
    [currentBaseBet],
  );
  const [totalBetMin, totalBetMax] = useMemo(
    () => [
      roundToTwo(currentBaseBet * betSizes[0]?.value * betLevels[0]?.value) || undefined,
      roundToTwo(currentBaseBet * betSizes[betSizes.length - 1]?.value * betLevels[betLevels.length - 1]?.value)
        || undefined,
    ],
    [currentBaseBet, betSizes, betLevels],
  );

  useEffect(() => {
    if (!currentBaseBet && baseBets.find(item => item)) {
      setCurrentBaseBet(baseBets[0]);
    }
  }, [currentBaseBet, baseBets]);

  if (!currentBaseBet) return null;

  return (
    <Box
      my={3}
      width="100%"
      maxWidth="1300px"
    >
      <FormControl
        variant="outlined"
        className={styles.selectContainer}
      >
        <InputLabel>{translate('ra.field.baseBet')}</InputLabel>
        <Select
          classes={{
            root: styles.select,
          }}
          value={currentBaseBet}
          label={translate('ra.field.baseBet')}
          onChange={e => setCurrentBaseBet(e.target.value)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {baseBets.map(baseBet => (
            <MenuItem
              key={baseBet}
              value={baseBet}
            >
              {baseBet}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box my={3}>
        <SingleBaseBetSettings
          baseBet={currentBaseBet}
          betSizes={betSizes}
          betLevels={betLevels}
          disabled
          hiddenLimitRange
          key={currentBaseBet}
        />
      </Box>
      <Box className={styles.detailBetLimitRange}>
        <DetailBetLimitRange
          totalBetMin={totalBetMin}
          totalBetMax={totalBetMax}
          disabled
        />
      </Box>
    </Box>
  );
};

export default BetSettingSelector;
