/* eslint-disable react/require-default-props, no-shadow */
import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm, Field, useFormState } from 'react-final-form';
import SingleBaseBetSettings from '../../../../base/components/bet-setting/bet-wheel-picker-container/single-base-bet-settings';

const SettingStep = ({
  disabled, baseBets,
}) => {
  const record = useRecordContext();
  const form = useForm();
  const {
    values: {
      betSizes: betSizesInForm, betLevels: betLevelsInForm, defaultBaseBet,
    },
  } = useFormState();

  const betSizesValidation = (value, values) => {
    const betSizesDefault = values.betSizes?.find(item => item.default);

    if (!values.betSizes?.length || !betSizesDefault) {
      return 'ra.validation.required';
    }

    if (!values.betLevels?.find(item => item.baseBet === betSizesDefault.baseBet && item.default)) {
      return 'ra.validation.required';
    }

    return undefined;
  };

  const betLevelsValidation = (value, values) => {
    const betLevelsDefault = values.betLevels?.find(item => item.default);
    if (!values.betLevels?.length || !betLevelsDefault) {
      return 'ra.validation.required';
    }

    if (!values.betSizes?.find(item => item.default && item.baseBet === betLevelsDefault.baseBet)) {
      return 'ra.validation.required';
    }

    return undefined;
  };

  return (
    <>
      <Field
        name="betSizes"
        validate={disabled ? () => {} : betSizesValidation}
      >
        {() => null}
      </Field>
      <Field
        name="betLevels"
        validate={disabled ? () => {} : betLevelsValidation}
      >
        {() => null}
      </Field>
      <Box
        sx={{
          '&>*': {
            marginBottom: 32,
          },
        }}
      >
        {baseBets
          ?.sort((a, b) => a - b)
          .map((baseBet, index) => {
            const allBetSizes = betSizesInForm || record.betSizes;
            const allBetLevels = betLevelsInForm || record.betLevels;
            const betSizes = allBetSizes?.filter(
              item => item.baseBet === baseBet
                  || (!item.baseBet && baseBet === (record.game?.gameConfig.baseBet || defaultBaseBet)),
            ) || [];
            const betLevels = allBetLevels?.filter(
              item => item.baseBet === baseBet
                  || (!item.baseBet && baseBet === (record.game?.gameConfig.baseBet || defaultBaseBet)),
            ) || [];

            const onAdd = (newValue, type) => {
              const data = type === 'betSizes' ? allBetSizes : allBetLevels;
              const isExistedValue = data?.find(
                item => item.value === newValue
                  && (item.baseBet === baseBet
                    || (!item.baseBet && baseBet === (record.game?.gameConfig.baseBet || defaultBaseBet))),
              );

              if (isExistedValue) return;
              const sameBaseBetData = data.filter(item => item.baseBet === baseBet
                || (!item.baseBet && baseBet === (record.game?.gameConfig.baseBet || defaultBaseBet)));

              const newData = [
                ...data,
                {
                  value: newValue,
                  baseBet,
                  default: !sameBaseBetData?.length,
                },
              ].sort((a, b) => a.value - b.value);

              form.change(type, newData);
            };

            const onDelete = (value, type) => {
              const data = type === 'betSizes' ? allBetSizes : allBetLevels;
              const valueIndex = data.findIndex(
                item => item.value === value
                  && (item.baseBet === baseBet
                    || (!item.baseBet && baseBet === (record.game?.gameConfig.baseBet || defaultBaseBet))),
              );
              if (valueIndex > -1) {
                data.splice(valueIndex, 1);
              }

              form.change(type, [...data]);
            };

            const onDefault = (value, type) => {
              const data = type === 'betSizes' ? allBetSizes : allBetLevels;
              const newData = data.map(item => {
                if (!(item.baseBet === baseBet
                  || (!item.baseBet && baseBet === (record.game?.gameConfig.baseBet || defaultBaseBet)))) {
                  return item;
                }

                return {
                  ...item,
                  default: item.value === value,
                };
              });

              form.change(type, newData);
            };

            return (
              <SingleBaseBetSettings
                baseBet={baseBet}
                betSizes={betSizes}
                betLevels={betLevels}
                key={baseBet}
                index={index}
                disabled={disabled}
                onAdd={onAdd}
                onDelete={onDelete}
                onDefault={onDefault}
              />
            );
          })}
      </Box>
    </>
  );
};

SettingStep.propTypes = {
  disabled: PropTypes.bool,
  baseBets: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SettingStep;
