/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import { Form, Input, InputNumber } from 'antd';
import { useTranslate } from 'react-admin';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Cancel, DeleteRounded, Edit, Save } from '@material-ui/icons';
import clsx from 'clsx';
import AntTable from '../../../../../base/components/antd/AntdTable';
import { formatNumber } from '../../../../../services/util/formatNumber';
import { truncate } from '../../../../../services/util/stringUtil';
import { MAX_NATIVE_ID_LENGTH } from '../../../../../constant/campaign';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    padding: '0px 4px',
  },
  root: {
    maxHeight: 'calc(100vh - 450px)',
    overflowY: 'scroll',
    '&.bo-table.ant-table-wrapper td.ant-table-cell': {
      verticalAlign: 'top',
    },
  },
  pagination: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    margin: '0 !important',
    padding: '12px 0',
    alignItems: 'center',
  },
  empty: {
    '&.bo-table.ant-table-wrapper td.ant-table-cell': {
      borderBottom: 'none',
    },
  },
  freeSpins: {
    minWidth: 180,
  },
  nativeId: {
    minWidth: 300,
  },
}));

export const FreeSpinsInput = ({
  stock, totalAddedFreeSpins = 0, ...props
}) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Form.Item
      name="freeSpins"
      rules={[
        {
          required: true,
          message: t('ra.validation.required'),
        },
        ({ getFieldValue }) => ({
          validator() {
            const currentFreeSpins = getFieldValue('freeSpins');
            const maxFreeSpins = stock ?? Infinity;
            const maxAddedFreeSpins = maxFreeSpins - totalAddedFreeSpins;

            if (typeof currentFreeSpins === 'number' && currentFreeSpins <= 0) {
              return Promise.reject(new Error(t('resources.campaign.validation.min-value', {
                min: 1,
              })));
            }

            if (currentFreeSpins > maxAddedFreeSpins) {
              return Promise.reject(new Error(t('resources.campaign.validation.max-value', {
                max: formatNumber('en', maxAddedFreeSpins),
              })));
            }
            return Promise.resolve();
          },
        }),
      ]}
      {...props}
    >
      <InputNumber
        step={1}
        precision={0}
        className={clsx(classes.input, classes.freeSpins)}
        placeholder={t('resources.campaign.fields.free-spins-placeholder')}
      />
    </Form.Item>
  );
};

export const NativeIdInput = ({
  currentPlayers = [], ...props
}) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Form.Item
      name="nativeId"
      rules={[
        {
          required: true,
          whitespace: true,
          message: t('ra.validation.required'),
        },
        {
          max: MAX_NATIVE_ID_LENGTH,
          message: t('resources.campaign.validation.max-length', {
            maxLength: MAX_NATIVE_ID_LENGTH,
          }),
        },
        ({ getFieldValue }) => ({
          validator() {
            const currentNativeId = getFieldValue('nativeId').trim();

            if (currentPlayers.find(player => player.nativeId === currentNativeId)) {
              return Promise.reject(new Error(t('resources.campaign.validation.duplicated-nativeId')));
            }
            return Promise.resolve();
          },
        }),
      ]}
      {...props}
    >
      <Input
        className={clsx(classes.input, classes.nativeId)}
        placeholder={t('resources.campaign.fields.nativeId-placeholder')}
      />
    </Form.Item>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  children,
  campaignData,
  totalAddedFreeSpins,
  currentPlayers,
  ...restProps
}) => (
  <td {...restProps}>
    {editing ? (
      dataIndex === 'freeSpins' ? (
        <FreeSpinsInput
          stock={campaignData?.stock}
          totalAddedFreeSpins={totalAddedFreeSpins}
        />
      ) : <NativeIdInput currentPlayers={currentPlayers} />
    ) : (
      children
    )}
  </td>
);

const EditablePlayersTable = ({
  data, setData, campaignData,
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = record => record.id === editingKey;

  const edit = record => {
    form.setFieldsValue({
      nativeId: record.nativeId?.trim() || '',
      freeSpins: record.freeSpins ?? '',
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async key => {
    try {
      const newRow = (await form.validateFields());

      const row = {
        ...newRow,
        nativeId: newRow.nativeId.trim(),
      };

      const newData = [...data];
      const index = newData.findIndex(item => key === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log(errInfo);
    }
  };

  const remove = record => {
    const newData = data.filter(item => item.id !== record.id);
    setData(newData);
  };

  const columns = [
    {
      title: t('resources.report.fields.nativeId'),
      dataIndex: 'nativeId',
      key: 'nativeId',
      className: classes.nativeId,
      editable: true,
      render: nativeId => (
        <Tooltip title={nativeId}>
          <Box>{truncate(nativeId, 24)}</Box>
        </Tooltip>
      ),
    },
    {
      title: t('resources.campaign.fields.freeSpins'),
      dataIndex: 'freeSpins',
      key: 'freeSpins',
      editable: true,
      className: classes.freeSpins,
      render: freeSpins => formatNumber('en', freeSpins),
    },
    {
      title: t('resources.campaign.fields.action'),
      key: 'action',
      width: '80px',
      fixed: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Box
            display="flex"
            sx={{
              gap: 4,
            }}
          >
            {editable ? (
              <>
                <Box
                  component={IconButton}
                  size="small"
                  color="primary"
                  sx={{
                    width: 'fit-content',
                  }}
                  onClick={() => save(record.id)}
                >
                  <Save fontSize="small" />
                </Box>
                <Box
                  component={IconButton}
                  size="small"
                  color="primary"
                  sx={{
                    width: 'fit-content',
                  }}
                  onClick={cancel}
                >
                  <Cancel fontSize="small" />
                </Box>
              </>
            ) : (
              <>
                <Box
                  component={IconButton}
                  size="small"
                  color="primary"
                  sx={{
                    width: 'fit-content',
                  }}
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                >
                  <Edit fontSize="small" />
                </Box>
                <Box
                  component={IconButton}
                  size="small"
                  color="primary"
                  sx={{
                    width: 'fit-content',
                  }}
                  disabled={editingKey !== ''}
                  onClick={() => remove(record)}
                >
                  <DeleteRounded fontSize="small" />
                </Box>
              </>
            )}
          </Box>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const [totalAddedFreeSpins, currentPlayers] = data?.reduce(([currentTotal, currentList], player) => {
          if (player.id !== record.id) {
            return [currentTotal + player.freeSpins, [...currentList, player]];
          }
          return [currentTotal, currentList];
        }, [0, []]);

        return {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          key: col.key,
          editing: isEditing(record),
          campaignData,
          totalAddedFreeSpins,
          currentPlayers,
        };
      },
    };
  });

  return (
    <Form
      form={form}
      component={false}
    >
      <AntTable
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        sticky
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          className: classes.pagination,
        }}
        scroll={{
          x: 'max-content',
        }}
        className={clsx(classes.root, {
          [classes.empty]: !data.length,
        })}
        wrapperProps={{
          className: classes.tableWrapper,
        }}
      />
    </Form>
  );
};

export default EditablePlayersTable;
