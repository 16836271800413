/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { DeleteForever, Star } from '@material-ui/icons';
import DefaultWheelPicker from '../../react-wheelpicker';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '12px 0',
    width: '100%',
  },
  deleteButton: {
    color: 'rgba(0, 0, 0, 0.12)',
    '&:hover': {
      background: 'transparent',
      cursor: 'pointer',
      color: '#E83A14',
    },
    "&[class*='disabled']": {
      visibility: 'hidden',
    },
  },
  defaultButton: {
    color: 'rgba(0, 0, 0, 0.12)',
    '&:hover': {
      background: 'transparent',
      cursor: 'pointer',
      color: '#005EB8',
    },
    "&[class*='disabled']": {
      visibility: 'hidden',
    },
  },
  disabledDefaultButton: {
    visibility: 'visible !important',
    color: '#005EB8 !important',
  },
}));

const DeleteButton = ({ data, ...otherProps }) => {
  const styles = useStyles();

  return (
    <IconButton className={styles.deleteButton} {...otherProps}>
      <DeleteForever />
    </IconButton>
  );
};

const DefaultButton = ({ data, defaultValue, ...otherProps }) => {
  const styles = useStyles();

  return (
    <IconButton
      className={clsx(styles.defaultButton, { [styles.disabledDefaultButton]: data === defaultValue })}
      {...otherProps}
    >
      <Star />
    </IconButton>
  );
};

const WheelPicker = ({ data, scrollerId, title, ...props }) => {
  const styles = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Box display="flex" flexDirection="column" alignItems={"center"}>
      {Boolean(title) && <Box mb={2} fontWeight={"bold"}>{title}</Box>}
      <div className={styles.root}>
      
      <DefaultWheelPicker
        animation="flat"
        data={data}
        height={40}
        parentHeight={40}
        fontSize={16}
        scrollerId={scrollerId}
        defaultSelection={selectedIndex}
        updateSelection={setSelectedIndex}
        DeleteButton={DeleteButton}
        DefaultButton={DefaultButton}
        {...props}
      />
    </div>
    </Box>
    
  );
};

WheelPicker.propTypes = {
  scrollerId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onDefault: PropTypes.func,
  onDelete: PropTypes.func,
  title: PropTypes.node,
  defaultValue: PropTypes.number, // This is the default value in the data list
  defaultSelection: PropTypes.number, // This is the index of the element that should be selected by default when the WheelPicker is rendered.
  updateSelection: PropTypes.func, // This function receives the selected index and can be used to update the state of the component that renders the WheelPicker. Using this selected index and the data array, we can see which option the user has selected
};

WheelPicker.propTypes = {
  onDefault: () => {},
  onDelete: () => {},
};

export default WheelPicker;
